import(/* webpackMode: "eager", webpackExports: ["CustomContent"] */ "/vercel/path0/apps/vercel-docs/app/components/switchers/custom-content.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PreferredExtension"] */ "/vercel/path0/apps/vercel-docs/app/components/switchers/preferred-extension.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Code","InlineCode"] */ "/vercel/path0/apps/vercel-docs/app/components/text/code.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/vercel-docs/app/components/text/linked-heading.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/vercel-docs/app/components/text/strong.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.0-canary.158_@babel+core@7.24.5_@opentelemetry+api@1.7.0_@playwright+test@1.44.1_ba_22vhrroyeiaz63rx3fj7ykwjju/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.0.0-canary.158_@babel+core@7.24.5_@opentelemetry+api@1.7.0_@playwright+test@1.44.1_ba_22vhrroyeiaz63rx3fj7ykwjju/node_modules/next/dist/shared/lib/lazy-dynamic/preload-chunks.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/docs-shared/src/components/table/table-body.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["TableRowCell","TableHeadCell"] */ "/vercel/path0/packages/docs-shared/src/components/table/table-cell.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/docs-shared/src/components/text/caption.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/docs-shared/src/components/table/table.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/docs-shared/src/components/table/tooltip.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/geist/src/components/badge/badge.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/vercel-docs/app/components/headings.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/vercel-docs/app/components/text/quote.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/docs-shared/src/components/layout/content.module.css");
;
import(/* webpackMode: "eager", webpackExports: ["Note"] */ "/vercel/path0/packages/geist/src/components/note/note.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PixelIcon"] */ "/vercel/path0/packages/geist/src/components/pixel-icon/index.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/geist/src/components/text/text.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/geist/src/components/tooltip/tooltip.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/geist/src/utils/use-await-paint-select-state.ts");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/geist/src/utils/use-delayed-render.ts");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/geist/src/utils/use-isomorphic-layout-effect.ts");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/geist/src/utils/use-media-query.ts");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/geist/src/utils/with-blur-base-64.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Link"] */ "/vercel/path0/packages/multi-zone/src/link/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["useParams"] */ "/vercel/path0/packages/vercel-segment/src/hooks/use-params.ts");
;
import(/* webpackMode: "eager", webpackExports: ["usePathname"] */ "/vercel/path0/packages/vercel-segment/src/hooks/use-pathname.ts");
;
import(/* webpackMode: "eager", webpackExports: ["useSelectedLayoutSegment"] */ "/vercel/path0/packages/vercel-segment/src/hooks/use-selected-layout-segment.ts");
;
import(/* webpackMode: "eager", webpackExports: ["useSelectedLayoutSegments"] */ "/vercel/path0/packages/vercel-segment/src/hooks/use-selected-layout-segments.ts");
